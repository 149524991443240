import React from 'react'
import { motion } from 'framer-motion'
import logo from '../logo.png'
import LINKEDIN from '../images/linkedIn.svg'
import FACEBOOK from '../clients/facebook.svg'
import TIKTOK from '../images/TikTok.svg'

function Footer() {
  return (
    <footer className="px-4 mt-12 divide-y  text-gray-100">
      <div className="container flex flex-col justify-between py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
        <div className="lg:w-1/3">
          <a rel="noopener noreferrer" href="#" className="flex justify-center space-x-3 lg:justify-start">
            <div className="flex items-center justify-center w-96">
              <img src={logo} alt="Logo" />
            </div>
          </a>
        </div>
        <div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-4">
          <div className="space-y-3">
            <h4 className="tracking-wide uppercase text-gray-50">Company</h4>
            <ul className="space-y-1">
              <li>
                <a rel="noopener noreferrer" href="#">
                  Jobs
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" href="#"></a>
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h4 className="tracking-wide uppercase text-gray-50">Legal</h4>
            <ul className="space-y-1">
              <li>
                <a href="/privacy.html" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/terms.html" target="_blank" rel="noopener noreferrer">
                  Terms
                </a>
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h4 className="uppercase text-gray-50">Media</h4>
            <ul className="space-y-1">
              <li>
                <a rel="noopener noreferrer" href="#">
                  Github
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" href="#">
                  Wiki
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" href="#"></a>
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h4 className=" uppercase text-gray-50">Community</h4>
            <div className="flex justify-start space-x-3">
              <motion.div whileHover={{ scale: 1.1 }}>
                <a
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/mediology/"
                  title="LinkedIn"
                  className="flex items-center p-1">
                  <img className="w-12" src={LINKEDIN} alt="LinkedIn" />
                </a>
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <a
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/MediologyWorld"
                  title="Facebook"
                  className="flex items-center p-1">
                  <img className="w-12" src={FACEBOOK} alt="Facebook" />
                </a>
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <a
                  rel="noopener noreferrer"
                  href="https://www.tiktok.com/@mediologyworld"
                  title="Tik Tok"
                  className="flex items-center p-1">
                  <img className="w-12" src={TIKTOK} alt="Tik Tok" />
                </a>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-6 text-sm text-center text-gray-400">© 2025 Mediology All rights reserved.</div>
    </footer>
  )
}

export default Footer
