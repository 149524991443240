import React from 'react'

function Culture() {
  return (
    <div className="px-4 py-16 m-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h1 className=" mb-6  text-5xl font-bold  text-center sm:text-10xl md:mx-auto">Culture</h1>
      </div>
      <div className="grid max-w-screen-lg gap-8 row-gap-10 mx-auto lg:grid-cols-2 text-center">
        <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
          <div className="mr-4 "></div>
          <div>
            <h1 className="mb-3 text-3xl font-bold leading-5">Smart with Heart</h1>
            <p className="mb-3 lg:text-xl  text-l">
              Our company vision emphasizes the growth of our people and the well-being of our clients. This philosophy prioritizes
              collaboration, inclusion, and mutual respect among our team, stakeholders and partners.
            </p>
          </div>
        </div>
        <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
          <div className="mr-4"></div>
          <div>
            <h1 className="mb-3 text-3xl font-bold leading-5">Positively Curious</h1>
            <p className="mb-3 lg:text-xl  text-l">
              We remain ahead of the curve in our industry through actively pursuing forward-thinking ideas with advanced technologies. Our
              passion lies in executing creative concepts through strategic campaigns that engage target audiences.
            </p>
          </div>
        </div>
        <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
          <div className="mr-4"></div>
          <div>
            <h1 className="mb-3 text-3xl font-bold leading-5">Human at the Core</h1>
            <p className="mb-3 lg:text-xl  text-l ">
              Mediology’s management team boasts a wealth of experience, with more than half of them having served at the agency for over a
              decade, demonstrating their commitment to our company and our people.
            </p>
          </div>
        </div>
        <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
          <div className="mr-4"></div>
          <div>
            <h1 className="mb-3 text-3xl font-bold leading-5">Collaborate with Love</h1>
            <p className="mb-3  lg:text-xl  text-l">
              We are creative thinkers, who harness the power of data, and create together to ultimately merge logic with magic. We truly
              value the innovation that comes with diverse collaboration.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Culture
