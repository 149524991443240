import { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import '../styles.css'
import Icon4 from '../images/icon4.svg'
import Icon5 from '../images/icon5.svg'

function About() {
  const control = useAnimation()
  const [ref, inView] = useInView()

  const caseVariant = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 1, scale: 0.8 }
  }
  useEffect(() => {
    if (inView) {
      control.start('visible')
    } else {
      control.start('hidden')
    }
  }, [control, inView])

  return (
    <div className="info">
      <h3
        className=" text-center mb-24 text-3xl font-extrabold 
    					  md:text-5xl lg:text-6xl mt-32 w-full m-auto lg:w-3/4">
        Get your brand to the root of what drives people's desires and behaviours.
      </h3>

      <div>
        <p
          className="text-lg font-normal text-gray-800 lg:text-xl
    			  mt-20 lg:w-2/3 m-auto w-full sm:w-10/12">
          We go beyond surface-level data, delving into the core of human behaviour to reveal the secrets that drive impactful work. Through
          our extensive research and advanced tools, we transcend demographics and psychographics to explore human nature. Our insatiable
          curiosity and innovative spirit drive us to unearth what truly motivates people.
          <br></br>
          <br></br>
          Our passion is not just in creating campaigns, but in crafting experiences that resonate with people on a deeper level. We believe
          in connecting with people's hearts and creating a lasting impact.
          <br></br>
          <br></br>
        </p>

        <motion.div ref={ref} variants={caseVariant} initial="hidden" animate={control} transition={{ duration: 0.5 }}>
          <div className="container px-6 py-10 mx-auto">
            <div className="lg:-mx-6 lg:flex lg:items-center w-full">
              <img className="w-full mx-auto lg:w-1/6 pb-12 pt-10" src={Icon4} alt="Icon" />

              <div className="w-full mt-8 lg:w-1/2 lg:px-6 lg:mt-0">
                <h3 className="w-full text-3xl font-semibold lg:text-4xl">Dream Census</h3>

                <p className="w-full lg:max-w-lg mt-6 text-gray-800 ">
                  We launched the Ithuba National Lottery at an all-time low, by connecting with Mzansi to discover real dreams,
                  authentically and credibly.
                  <br></br>
                  <br></br>
                  <em>My One Thing</em> started as an on-the-ground activation and became the heart of our campaign. People’s dreams became
                  our TV spots and outdoor billboards; real dreams made real content.
                  <br></br>
                  <br></br>
                  This resulted in an increase in sales of 13.5%, as well as 225% facebook engagement and 283% more twitter engagement.
                </p>

                <h3 className="mt-6 text-2xl font-semibold">Client</h3>
                <p className="text-gray-800 text-xl">Ithuba National Lottery</p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div ref={ref} variants={caseVariant} initial="hidden" animate={control} transition={{ duration: 1.8 }}>
          <div className="container px-6 py-10 mx-auto ">
            <div className="lg:-mx-6 lg:flex lg:items-center">
              <div className="mt-8 lg:w-1/3 lg:px-6 lg:mt-0 mx-auto">
                <h3 className="text-4xl font-semibold lg:text-4xl">Adopt Don’t Shop</h3>

                <p className="max-w-lg mt-6 text-gray-800 ">
                  This campaign was based on human behaviour and led through social listening. We built on the insight that 40% of our
                  target market was made up of single people who begrudgingly participate in the Valentine’s Day gifting sensation.
                  <br></br>
                  <br></br>
                  We unlocked brand love in a low involvement category by sponsoring the adoption fee of a pet, encouraging our audience to
                  #AdoptDontShop.
                  <br></br>
                  <br></br>
                  We achieved a 14% increase in year-on-year sales through this Valentine’s Day promotion.
                </p>

                <h3 className="mt-6 text-2xl font-semibold">Client</h3>
                <p className="text-gray-800 text-xl">Supa Quick</p>
              </div>
              <img className="w-4/6 mx-auto lg:w-2/12 pb-12 pt-10" src={Icon5} alt="Icon" />
            </div>
          </div>
        </motion.div>
        {/*
      <div  className='flex pt-10 justify-end w-5/6'>
            <motion.div
                  whileHover={{ scale: 1.2 }}
                  >
                  <button className='button'>Learn more</button>
            </motion.div>
    </div>
      */}
      </div>
    </div>
  )
}

export default About
