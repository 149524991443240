import React from 'react'
import { useRef } from 'react'
import { useTransform, useScroll, motion, useSpring } from 'framer-motion'
import '../styles.css'
import logo from '../logo.png'
import Mlogo from '../Mediology_logo.svg'

const Navbar = () => {
  const navRef = useRef(null)

  return (
    <motion.header className="header" initial={{ y: '-100px' }} animate={{ y: '30px' }} transition={{ duration: 2 }}>
      <img style={{ width: '130px', height: '130px' }} src={logo} alt="Logo" />
      <nav className="nav " ref={navRef}></nav>
      <img className=" w-20 lg:w-52 " src={Mlogo} alt="Mediology" />
    </motion.header>
  )
}
export default Navbar
