import {React, useRef, useState, useEffect} from 'react'
import { useTransform, useScroll, motion, useSpring } from "framer-motion";
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";


function Newsletter() {

	const url = "https://mediologyworld.us21.list-manage.com/subscribe/post?u=eebf8504b2aae0a0c47f51ad5&amp;id=c11cd16423&amp;f_id=00b943e1f0";	

	const { loading, error, success, message, handleSubmit } = useMailChimpForm(
		url
	  );
	  const { fields, handleFieldChange } = useFormFields({
		EMAIL: ""
	  });

	const targetRef = useRef<HTMLDivElement | null>(null);
	const { scrollYProgress } = useScroll({
	  target: targetRef,
	  offset: ["start end", " end start"],
	});
  
	const scale = useTransform(scrollYProgress, [0, 0.3], [0, 1.4]);

  return (
    <motion.div useRef={targetRef}  style={{scale}} className="w-full lg:w-1/2 mx-auto" >
	<div className="container flex flex-col flex-wrap content-center justify-center p-4 py-4  
	mb-48 mt-48 mx-auto md:p-2">
		<h1 className="text-5xl lg:text-7xl text-center
		 pb-4 font-extrabold  mx-auto">Stay ahead of the competition</h1>
		<p className="pt-2 pb-8 text-xl antialiased text-center text-gray-100">
			Get valuable insights and other relevant industry news right to your mailbox. </p>
		

		<div className="">
		<form
        	onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(fields);
        }}
      >
			<input 
			id="EMAIL"
			autoFocus
			type="email"
			value={fields.EMAIL}
			onChange={handleFieldChange}
			placeholder="example@email.com" 
			className="w-3/5 p-3 rounded-l-lg sm:w-2/3 text-black" 
			/>
			<button 
			type="submit" 
			className="w-2/5 p-3 font-semibold rounded-r-lg sm:w-1/3 
			bg-purple-600 text-gray-300" >
			Subscribe
			</button>
			</form>
					{loading && "submitting"}
					{error && message}
					{success && message}
		</div>
		
		
		<p className="pt-10 pb-8 text-small italic antialiased text-center text-gray-100">
		We belong to the world’s leading independent agencies network, thenetworkone, giving us 
		the benefit of a global affiliation while maintaining our independence. </p>
	</div>
</motion.div>
  )
}

export default Newsletter


