import React from 'react'
import Icon8 from '../images/icon8.svg'
import Icon9 from '../images/icon9.svg'
function Cases2() {
  return (
    <div className="container px-6 py-10 mx-auto lg:flex lg:items-center ">
        <div className="lg:-mx-6 ">
                 <img  className='w-4/6 lg:w-2/6 pb-12 pt-10 mx-auto'  src={Icon8} alt="Icon" />
            
            <div className="mt-8 lg:w-3/4 lg:px-6 lg:mt-0 mx-auto">
              

                <h1 className="text-4xl font-semibold lg:text-4xl">
                Let the Adventure Begin!
                </h1>

                <p className=" mt-6 text-white ">
                With an in-depth media strategy, underpinned by extensive business and digital 
                trends analyses, our campaign led to an increase of 150% in annual online sales.

                    <br></br><br></br>
                    The campaign increased online awareness of Club Med, and drove site bookings, 
                    while achieving strict multi-tiered goals and KPI’s.
                    
                Additionally, we achieved an 89% lift in brand impressions, and 70% increase (year-on-year) 
                in campaign conversion rate.
                </p>

                <h1 className="mt-6 text-2xl font-semibold">Client</h1>
                <p className="text-gray-300 text-xl">Club Med</p>  
            </div>
        </div>
        <div className="lg:-mx-6 ">
                 <img  className='w-4/6 lg:w-2/6 pb-12 pt-16 mx-auto'  src={Icon9} alt="Icon" />
            
            <div className="mt-8 lg:w-3/4 lg:px-6 lg:mt-0 mx-auto">
              

                <h1 className="text-4xl font-semibold lg:text-4xl">
                The Power of Music
                </h1>

                <p className=" mt-6 text-white ">
                We launched a global <em>Drive App</em> campaign in South Africa to position Dezeer 
                as a stand-alone music streaming app.
                    <br></br><br></br>
                    A multi-media approach was implemented, ensuring the message was reinforced 
                    throughout the consumer journey.  We gave the campaign a local flavour by 
                    partnering with HOT FM, making it the first radio station in SA that was 
                    streamed via a music app.

                <br></br><br></br>
                Our campaign generated a cost per install at a ¼ of global targets at a cost to 
                client of R5 million with a value of R11,4 m.

                </p>

                <h1 className="mt-6 text-2xl font-semibold">Client</h1>
                <p className="text-gray-300 text-xl">Deezer</p>  
            </div>
        </div>
        
    </div>

    
  )
}

export default Cases2