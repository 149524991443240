import {React, useRef} from 'react'
import { useTransform, useScroll, motion, useSpring } from "framer-motion";
import SPLASH1 from '../images/splash1.jpg'


function Splash() {

  const targetRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", " end start"],
  
  });

  const scale = useTransform(scrollYProgress, [0, 0.3], [3, 0.1]);


  return (
    <motion.div useRef={targetRef} style={{}} >
        
        <img className=' rounded-3xl mx-auto lg:w-10/12 p-4'  src={SPLASH1} alt="Splash Image" />

    </motion.div>
  )
}

export default Splash