import React from 'react'
import Icon6 from '../images/icon6.svg'
import Icon7 from '../images/icon7.svg'

function Cases() {
  return (
    <div className="container px-6 py-10 mx-auto lg:flex lg:items-center ">
        <div className="lg:-mx-6 ">
                 <img  className='w-4/6 lg:w-2/6 pb-12 pt-10 mx-auto'  src={Icon6} alt="Icon" />
            
            <div className="mt-8 lg:w-3/4 lg:px-6 lg:mt-0 mx-auto">
              

                <h1 className="text-4xl font-semibold lg:text-4xl">
                She Owns Her Success
                </h1>

                <p className=" mt-6 text-white ">
                Societal norms have forced women to downplay their success and not actively 
                participate in their own financial investments. Momentum believes in a fair and 
                equitable world, where all success should be celebrated.
                    <br></br><br></br>
                    As part of a 360 integrated strategy, we worked with strong powerful 
                    female broadcasters, whose voices led the movement. This resulted in an 
                    increase from 360 to 8703 registrations year on year to the power-packed 
                    financial workshops.
                    <br></br><br></br>
                </p>

                <h1 className="mt-6 text-2xl font-semibold">Client</h1>
                <p className="text-gray-300 text-xl">Momentum</p>  
            </div>
        </div>
        <div className="lg:-mx-6 ">
                 <img  className='w-4/6 lg:w-2/6 pb-12 pt-10 mx-auto'  src={Icon7} alt="Icon" />
            
            <div className="mt-8 lg:w-3/4 lg:px-6 lg:mt-0 mx-auto">
              

                <h1 className="text-4xl font-semibold lg:text-4xl">
                A Diamond is Forever
                </h1>

                <p className=" mt-6 text-white ">
                We delivered a fully integrated launch campaign for Forevermark Diamonds.
                <br></br><br></br>
               
                Our strategic direction led to a resounding success. Editors and influencers 
                generated launch content, saturating the beauty world with multi-content 
                formats. Skillful use of PR and digital applications maximized brand awareness.
                    
                <br></br><br></br>
                At a cost of R1,8 million, we achieved highly credible exposure valued at R14 million.
                </p>

                <h1 className="mt-6 text-2xl font-semibold">Client</h1>
                <p className="text-gray-300 text-xl">De Beers</p>  
            </div>
        </div>
        
    </div>

    
  )
}

export default Cases