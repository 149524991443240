import React from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
//import Love from './Love'
//import Adfocus from '../images/adfocus.png'
//import Adreview from '../awards/adreview.png'
//import Apex from '../awards/apex.png'
//import Assegai from '../awards/assegai.png'
//import Indie from '../awards/indie.png'
//import Cannes from '../awards/Lion_Cannes.svg'
//import Loerie from '../awards/loeries.png'
//import Mark from '../awards/mark.png'
//import Most from '../awards/most.png'
//import Scopen from '../awards/scopen.jpeg'
//import Network from '../awards/thenetworkone.svg'
import '../styles.css'

function Awards() {
  return (
    <div className=" award  sm:pb-32 mx-auto lg:w-10/12">
      <h1
        className="mt-4 text-center mb-2 text-3xl font-extrabold 
    					  md:text-8xl lg:text-9xl  w-12/12 mx-auto lg:w-12/12  pb-4">
        CONSISTENTLY AWARD WINNING AGENCY
      </h1>

      <div className="grid grid-cols-2 lg:grid-cols-5 md:grid-col-3 gap-4 lg:w-10/12  mx-auto pt-20 p-14 lg:pl-24 text-l">
        <p> FM Adfocus </p>
        <p> FM AdReview </p>
        <p> Most </p>
        <p> Scope </p>
        <p> Apex </p>
        <p> Assegai </p>
        <p> Cannes Lions </p>
        <p> Loeries </p>
        <p> Indie </p>
        <p> Mark Lives </p>
      </div>
    </div>
  )
}

export default Awards
