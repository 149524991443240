import React from 'react'
import { useTransform, useScroll, motion, useSpring } from 'framer-motion'
import '../styles.css'

function Heading() {
  return (
    <motion.div initial={{ opacity: 0, y: '-400px' }} animate={{ opacity: 1, y: '-150px' }} transition={{ duration: 2 }}>
      <div className="header items-center justify-center ">
        <h2
          className=" text-inherit pl-14 lg:pl-20 text-5xl lg:text-10xl  pb-10 
                      pt-52 lg:pt-96 m-auto 
                      lg:w-5/6 w-full md:w-full
                      lg:leading-tight leading-normal font-bold ">
          WHERE LOGIC <br></br>MEETS MAGIC ™
        </h2>
      </div>
    </motion.div>
  )
}

export default Heading
