import React from 'react'
import Momentum from '../clients/momentum.svg'
import Metropolitan from '../clients/metropolitan.svg'
import Bridgestone from '../clients/bridgestone.svg'
import Firestone from '../clients/firestone.svg'
import Flysafair from '../clients/flysafair.svg'
import Nativa from '../clients/nativa.png'
import Distel from '../clients/distel.svg'
import WWF from '../clients/wwf.svg'
import XBOX from '../clients/xbox.svg'
import FACEBOOK from '../clients/facebook.svg'
import KIKKOMAN from '../clients/kikkoman.png'
import MICROSOFT from '../clients/microsoft.svg'
import RCS from '../clients/rcs.png'
import MOBICRED from '../clients/mobicred.png'
import AUTO from '../clients/autopedigree.png'
import IDC from 	'../clients/idc.png'
import MIELE from '../clients/miele.svg'
import SAFRIPOL from '../clients/safripol.png'
import SUPAQUICK from '../clients/supaquick.svg'
import TABASCO from '../clients/tabasco.svg'
import NASHUA from '../clients/nashua.svg'
import TWINSAVERS from '../clients/twinsavers.webp'
import ZODIAC from '../clients/zodiac.svg'
import GREENTRUST from '../clients/greentrust.jpeg'

function Clients() {
  return (
    <section className="clients ">
	<div className="container p-10 mx-auto text-center pt-24 ">
    <h3 className=" text-center mb-4 text-4xl font-extrabold 
    			 md:text-5xl lg:text-6xl">
	  Some of our amazing clients
        
         </h3>
	</div>
	<div className="grid lg:grid-cols-6 lg:gap-16 grid-cols-2 gap-16 gap-y-20 lg:w-10/12 w-11/12
					justify-center mx-auto pt-20 items-center">
		<div className="">
		<img   src={Momentum} alt="Momentum" />
		
		</div>
		<div className="">
		<img  style={{ marginTop:'-15px' }} src={Metropolitan} alt="Metropolitan" />
		</div>
		<div className="">
		<img   src={Bridgestone} alt="Bridgestone" />
		</div>
		<div className="">
		<img   src={Firestone} alt="Firestone" />
		</div>
		<div className="">
		<img  style={{ marginTop:'-15px' }} src={Flysafair} alt="Flysafair" />
		</div>
		<div className="">
		<img  style={{width:'100px', marginTop:'-45px' }} src={Nativa} alt="Nativa" />
		</div>
		<div className="">
		<img   src={Distel} alt="Distel" />
		</div>
		<div className="">
		<img  style={{width:'100px', marginTop:'-20px'}} src={WWF} alt="wwf" />
		</div>

		<div className="">
		<img  style={{}} src={XBOX} alt="xbox" />
		</div>
		<div className="">
		<img  style={{width:'100px', marginTop:'-20px'}} src={FACEBOOK} alt="Facebook" />
		</div>
		<div className="">
		<img  style={{}} src={KIKKOMAN} alt="Kikkoman" />
		</div>
		<div className="">
		<img  style={{}} src={MICROSOFT} alt="Microsoft" />
		</div>

		<div className="">
		<img style={{ }} src={RCS} alt="rcs" />
		</div>
		<div className="">
		<img style={{}} src={MOBICRED} alt="Mobicred" />
		</div>
		<div className="">
		<img  style={{width:'120px', marginTop:'-10px'}} src={AUTO} alt="AutoPedigree" />
		</div>
		<div className="">
		<img  style={{}} src={IDC} alt="Idc" />
		</div>

		<div className="">
		<img  style={{ }} src={MIELE} alt="Miele" />
		</div>
		<div className="">
		<img style={{ }} src={SAFRIPOL} alt="Safripol" />
		</div>
		<div className="">
		<img  style={{ }} src={SUPAQUICK} alt="SupaQuick" />
		</div>
		<div className="">
		<img style={{width:'140px', marginTop:'-50px'}} src={TABASCO} alt="Tabasco" />
		</div>
		<div className="">
		<img style={{width:'', marginTop:''}} src={NASHUA} alt="Nashua" />
		</div>
		<div className="">
		<img style={{width:'', marginTop:''}} src={TWINSAVERS} alt="Twinsavers" />
		</div>
		<div className="">
		<img style={{width:'', marginTop:''}} src={ZODIAC} alt="Zodiac" />
		</div>
		<div className="">
		<img style={{width:'', marginTop:''}} src={GREENTRUST} alt="WWF Nedbank Green Trust" />
		</div>
	</div>
</section>
  )
}

export default Clients