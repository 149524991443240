import React from 'react'
import { useState, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Points, PointMaterial } from '@react-three/drei'
import * as random from 'maath/random/dist/maath-random.esm'
import '../styles.css'

function Stars(props) {

    const ref = useRef()
  const [sphere] = useState(() => random.inSphere(new Float32Array(1500), 
                                { radius: 1.5 }))
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 10
    ref.current.rotation.y -= delta / 15
  })

  const maxVal = 0xFFFFFF; // 16777215
  let randomNumber = Math.random() * maxVal; 
  randomNumber = Math.floor(randomNumber);
  const randColor = randomNumber.toString(16);

  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
        <PointMaterial transparent color={randColor} size={0.01} sizeAttenuation={true} depthWrite={false} />
      </Points>
    </group>
  )
}

export default Stars