import {useState, useRef} from 'react'
import { motion } from "framer-motion";

//import * as Yup from "yup";
import emailjs from '@emailjs/browser';

function Contact() {
	
	const [buttonState, setButtonState] = useState('Send Message');
	const form = useRef();
  
	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_oa2tnao', 
						'template_gpgwrhi', form.current, 
						'D0XKMD4cHOummiTNw')
		  .then((result) => {
			  console.log(result.text);
		  }, (error) => {
			  console.log(error.text);
		  });
		setButtonState('Message Sent!');
	}



  return (
    <div className="grid w-3/3 lg:w-2/3 grid-cols-1 gap-8 px-8 py-16 mx-auto  
					md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 text-gray-100 ">
	<div className="flex flex-col justify-between">
		<div className="space-y-2">
			<h1 className="text-4xl font-bold leading-tight lg:text-6xl">Let's talk!</h1>
			<div className="text-gray-300">Thank you for taking the time to contact us. 
			We're looking forward to connecting with you and appreciate your interest in our Company.</div>
		</div>
		
        <p className="flex items-center mt-10  ">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
						<path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
					</svg>
					<span>The Sentinel.

					86 Capricorn Rd,

					Lonehill East, Sandton</span>
				</p>
				<p className="flex items-center">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
						<path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
					</svg>
					<span>+27 11 707 4800</span>
				</p>
				<p className="flex items-center mt-10">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
						<path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
					</svg>
					<span>Old Castle Brewery, Office 411,

							6 Beach Rd,

							Woodstock, Cape Town</span>
				</p>
				<p className="flex items-center">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
						<path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
					</svg>
					<span>+27 21 447 1397</span>
				</p>
				<p className="flex items-center mt-10  ">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
						<path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
					</svg>
					<span>826 West 7th, Vancouver, V5Z 1C1 Canada</span>
				</p>
				<p className="flex items-center">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
						<path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
					</svg>
					<span>+1 236 998 8648</span>
				</p>
				<br></br>
				<p className="flex items-center pt-4">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
						<path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
						<path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
					</svg>
					
					<span>ana@mediologyworld.com</span>
				</p>
	</div>
	<form
      className="space-y-6 mt-44"
	  ref={form} 
	  onSubmit={sendEmail}
    >	
      <div>
        <label htmlFor="name" className="text-sm">
          Full name *
        </label>
        <input
          name="user_name"
          type="text"
          placeholder=""
          className="w-full p-3 rounded bg-gray-100 text-black"

          
          required
        />
      </div>
      <div>
        <label htmlFor="email" className="text-sm">
          Email *
		 
        </label>
        <input
          name="user_email"
          type="email"
          className="w-full p-3 rounded bg-gray-100 text-black"
         
          required
        />
      </div>
      <div>
        <label htmlFor="message" className="text-sm">
          Message *   
		  
        </label>
        <textarea
          name="message"
          rows="3"
          className="w-full p-3 rounded bg-gray-100 text-black"
       
          required
        ></textarea>
      </div>
      <motion.div whileHover={{ scale: 1.1 }}>
        <button
          type="submit"
		  value='send'
          className="w-full p-3 text-sm font-bold tracking-wide 
		  uppercase rounded bg-orange-400 text-gray-900"
        >
          {buttonState}
        </button>
      </motion.div>
    </form>
</div>
  )
}

export default Contact
