import {React, useRef} from 'react'
import { useTransform, useScroll, motion, useSpring } from "framer-motion";
import SPLASH3 from '../images/splash4.jpg'


function Splash4() {

  const targetRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
   
  
  });

  const scale = useTransform(scrollYProgress, [0, 0.3], [0, 0.9]);


  return (
    <motion.div useRef={targetRef} style={{}} >
        
        <img className=' rounded-3xl p-4  mx-auto lg:w-10/12'  src={SPLASH3} alt="Splash Image" />

    </motion.div>
  )
}

export default Splash4