import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import App from './App'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

import './styles.css'

posthog.init('phc_VRmR1oLfKwmRRwgfnZ5uOwMGw2gU4uzvYYVnwSrxwqd', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only'
})

ReactGA.initialize('G-QLJ6RY5188')
// Send pageview with a custom path
ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>
)
